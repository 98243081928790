<template>
	<div class="page">
		<van-nav-bar title="注册">
		</van-nav-bar>

		<div class="logo">
			<van-image
				round
				width="90px"
				height="90px"
				fit="cover"
				:src="require('@/assets/logo.png')"
			/>
			<van-image
				class="txt-logo"
				width="100px"
				:src="require('@/assets/logo2.png')"
			/>
		</div>
		
		<van-form validate-first @submit="register">
			<div class="form-box">
				<div class="form-title">
					手机号
				</div>
				<van-field
					v-model="form.phone"
					placeholder="请输入手机号"
					:rules="[{ validator: validPhone, message: '手机号输入有误' }]"
				/>
				<div class="form-title">
					密码
				</div>
				<van-field
					v-model="form.userPwd"
					type="password"
					placeholder="请输入密码"
					:rules="[{ validator: validPwd, message: '密码由数字与字母组成，且至少6位' }]"
				/>
				<div class="form-title">
					邀请码
				</div>
				<van-field
					v-model="form.validate"
					placeholder="请输入邀请码，如没有请联系你的邀请人"
					:rules="[{ required: true, message: '请输入邀请码' }]"
				/>
				
				<div class="form-checked">
					<van-checkbox v-model="checked" icon-size="18px">同意</van-checkbox>
					<span @click="agreementDialog = true">《无限AI用户协议》</span>
				</div>
			</div>
	
			<div class="btn-box">
				<van-button type="default" @click="backApp">返回</van-button>
				<van-button type="info" native-type="submit">立即注册</van-button>
			</div>
		</van-form>

		<van-dialog
			v-model="agreementDialog"
			title="“无限AI”用户协议"
			show-cancel-button
			confirmButtonText="同意"
			@confirm="checked = true">
			<div class="agreement-content">
				<p>欢迎使用“无限AI”软件及服务！</p>
				<p>《无限AI用户协议》（以下简称《本协议》）是您与“无限AI”及其全部服务主体（以下简称“指令RPA”）之间的协议。</p>
				<p>请您务必谨慎阅读、充分理解本协议各条款内容，特别是免除或者限制责任的条款，并选择接受或不接受。一旦您使用“软件服务”，即表明您完全同意并接受本协议各项条款，同时包括接受软件服务对协议各项条款随时所做的任何修改。如果您不同意本协议中的条款，您无权使用本“软件服务”或其任何更新。</p>
				<h4>一、定义</h4>
				<p>“软件服务”：指通过“无限AI”服务者指定的下载，并仅限在相应的操作系统中安装、使用的软件服务。</p>
				<p>“更新”： 包括但不限于错误修正程序、补丁程序、更新、升级、增强、新版软件服务等。</p>
				<p> “测试版”： 指不可商用的“无限AI”账号或程序版本。</p>
				<h4>二、软件许可/授权</h4>
				<p>在您完全接受并遵守本协议的基础上，本协议授予您使用“软件服务”的某些权利和非独占性许可，允许您依据本协议各项条款许可的用途使用“软件服务”，分叉智能保留所有其他权利。</p>
				<p>当前版的用途仅为“非商业用途”。您可以在“无限AI”官网上免费下载使用，“无限AI”不作出任何形式的担保的情况下按“现状”向您提供的，将当前版本用于商业用途，由此造成的一切损失（包括因此给“无限AI”造成的损失）将由您负责，“无限AI”不对当前版本承担任何责任。如您需要继续使用软件服务进行商业行为，您需要联系“无限AI”商务人员并签订正式书面合同，同时依据您所获得的授权服务类型中确定的技术支持期限、技术支持方式和技术支持内容获得指定范围内的技术支持服务。</p>
				<h4>三、限制和义务</h4>
				<p>未获得本“软件服务”商业授权之前，您不可将本“软件服务”用于商业用途。</p>
				<p>无论如何，即无论用途如何、是否经过修改或美化、修改程度如何，只要使用本“软件服务”的整体或任何部分，未经分叉智能官方书面授权许可，软件前台和后台页面页脚处的版权标识和链接都必须保留，而不能修改或删除（如有）。</p>
				<p>“无限AI”提供的或您获得的有关本“软件服务”的任何信息只能由您为本协议许可的目的而使用；您不可抄袭本软件进行任何形式重制。</p>
				<p>对于从“无限AI”取得的任何信息、软件、产品或服务，您不可对其进行修改、改编、反编译、逆向工程、反汇编及绕过“软件服务”中的任何技术限制、或基于以上内容创建衍生作品、或以其他方式试图从本“软件服务”取得源代码、或重新包装本“软件服务”、或从“软件服务”使用文档中摘取其实质部分作其他应用。</p>
				<p>您不可对本“软件服务”以及与之关联的商业授权进行发布、出租、销售、分销、抵押、转让、许可或发放子许可证。</p>
				<p>本“软件服务”商业授权版本可能包含一些独立功能或特性，这些功能只有在您购买本软件商业授权后才可以使用。在未取得商业授权的情况下，您不可使用、尝试使用或复制这些授权版本独立功能，也不得以其他方式绕过控制此类功能的技术。</p>
				<p>不得用于非法或禁止的用途：您在使用本“软件服务”或服务时，不得将本“软件服务”产品或服务用于任何非法用途，以及本协议条款和软件中声明禁止的用途。</p>
				<p>您在使用本软件服务时须遵守法律法规，不得利用本软件服务从事违法违规行为，包括但不限于：</p>
				<p>发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；</p>
				<p>发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；</p>
				<p>发布、传送、传播广告信息及垃圾信息；</p>
				<p>恶意虚构事实、隐瞒真相以误导、欺骗他人；</p>
				<p>被认定成为破坏任何主体的计算机及网络系统的行为 ；</p>
				<p>其他法律法规禁止的行为；</p>
				<p>如果您违反了上述约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求“无限AI”给予协助。造成损害的，您应依法予以赔偿，“无限AI”不承担任何责任。给分叉智能造成损失的，分叉智能保留追究的权利。</p>
				<p>您有责任自行备份存储在本软件服务中的数据。</p>
				<h4>四、知识产权</h4>
				<p>“无限AI”保留所有权利，包括但不限于知识产权。本软件服务只供许可使用，除上述明确允许的方式外，不得为任何营利性或非营利性的目的以任何其他方式安装、复制或使用”“无限AI”” 的“软件服务”。 “无限AI”保留本软件服务未明确授权的其他所有权利；包括但不限于“软件服务”的结构、组织和代码以及与本软件服务相关的所有信息内容，均受著作权法和其他知识产权法律法规的保护。</p>
				<p>“无限AI”在本软件服务中提供的内容（包括但不限于浏览页、文字、图片、图表、图形、标识、服务标记等）的知识产权归“无限AI”及其所有运营主体所有。</p>
				<p>未经“无限AI”明确授权，您不可复制、模仿、使用或发布与软件服务相关的文字、图片、图表、图形、标识等，也不得修改或删除应用软件产品中体现“无限AI”任何标识或身份信息。</p>
				<p>本协议项下的软件服务知识产权包括“无限AI”现有以及将有的所有知识产权。</p>
				<br/>
				<h4>五、版本更新</h4>
				<p>本软件会在连接到互联网的状态下自动检查是否有可下载到您的计算机的更新并下载安装。接受版本更新即表示您自愿终止本软件服务任何以前版本的权利。</p>
				<h4>六、用户个人信息管理和隐私保护</h4>
				<p>您理解,当您访问“软件服务”时，您需要在“无限AI”系统平台注册账号和设置密码，在注册过程中可能需要您提供包括但不限于电话号码、电子邮箱、工作单位名称等个人信息。</p>
				<p>您通过自身账号在接受分叉智能系统平台的各项服务中所上传、发送的任何内容都应具有合法来源，如相关内容涉及任何第三方的合法权益，应事先获得相应的许可。如“无限AI”收到涉及您的相关举报或投诉，“无限AI”可采取相关法律法规所要求或允许的方式，向相关争议方、相关部门提供包括账号在内的必要的信息，以便解决纠纷争议，保护正当权利人的合法权益。 </p>
				<p>“无限AI”将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</p>
				<p>软件服务的所有权归分叉智能所有，您完成注册后，仅获得软件服务的使用权；您不可赠与、借用、租用、转让或售卖您在分叉智能系统平台开通的账号或者以其他方式许可任何第三方使用平台账号。</p>
				<p>您有责任妥善保管注册账号信息及密码的安全，您需要对注册账号的行为承担法律责任。您同意在任何情况下不向他人透露账号及密码信息。当在您怀疑他人在使用您的账号时，您应立即通知“无限AI”。为了不断提高本软件的质量，“无限AI”将有可能收集您提供的个人信息以及您对本软件进行使用的信息，并不定期的予以反馈。以上收集的信息仅仅是为了市场分析的需要，以便今后能够为您提供更好的功能和服务，分叉智能对此数据严格保密。除非：相关法律法规或法院、政府机关要求；为保证社会公众、“无限AI”客户及员工的合法权益所必要且适当；作为合并、收购、资产转让或类似交易的一部分而转移；或为提供您要求的服务所必需。法律法规规定的其他情形。</p>
				<p>登录使用本软件即视为您授权我们搜集并使用以上信息，认可“无限AI”的操作，愿意承担因此而可能造成的损失。</p>
				<p>关于用户个人信息的搜集、处理、存储和使用以及用户隐私的保护详见 《“无限AI”隐私政策》。</p>
				<h4>七、免责声明</h4>
				<p>除法律法规有明确规定外，“无限AI”将尽最大努力确保本软件及其所涉及的技术及信息安全、有效、准确、可靠，但受限于现有技术，您理解“无限AI”不能对此进行担保。</p>
				<p>您理解，“无限AI”将依照法律规定履行基础保障义务，因使用本软件服务所引起或与此有关的人身伤害或附带的、间接的损害赔偿，包括但不限于利润损失、资料数据损失、业务中断的损害赔偿或其它商业损害赔偿或损失，需由您自行承担。</p>
				<p>非经“无限AI”授权开发并正式发布的其它任何由本软件衍生的软件均属非法，下载、安装、使用此类软件，可能导致不可预知的风险，由此产生的法律责任与纠纷与“无限AI”无关，“无限AI”有权中止、终止使用许可和/或其他一切服务。</p>
				<p>您与其他使用本软件的用户之间通过本软件进行各项活动时，因您受误导或欺骗而导致或可能导致的任何心理、生理上的伤害以及经济上的损失，均应由过错方依法承担所有责任。</p>
				<p>若发生不能预见、不能避免、无法克服的情况，包括但不限于因受到网络攻击、电脑病毒、网络或服务器故障、第三方提供软件对本软件的政策等原因引起的服务器或终端崩溃、自然灾害、政府政策调整、罢工、暴动、火灾、水灾等，造成本软件者无法提供服务的，本软件开发者有权在未事先通知的情况下暂停各项服务，若因此对您造成的不利或损害，“无限AI”将不承担任何责任。</p>
				<p>“无限AI”不对您在使用本“软件服务”过程中添加的任何信息内容以及信息导致的任何版权纠纷、法律争议和后果承担任何责任，全部责任由您自行承担。</p>
				<h4>八、权利和所有权的保留</h4>
				<p>“无限AI”保留所有未在本协议中明确授予您的所有权利。“无限AI”保留随时更新本协议的权利，并将公示于“无限AI”的官网及软件页面，更新后的内容于公示即时生效。您可以随时咨询“无限AI”查阅最新版许可条款，在更新生效后您继续使用本“软件服务”则被视作您已接受了新的条款。</p>
				<h4>九、协议终止与争议解决</h4>
				<p>您一旦开始复制、下载、安装或者使用本“软件服务”，即被视为完全理解并接受本协议的各项条款，在享有上述条款授予的许可权利的同时，也受到相关的约束和限制，本协议许可范围以外的行为，将直接违反本协议并构成侵权。</p>
				<p>一旦您违反本协议的条款，“无限AI”随时可能终止本协议、收回许可和授权，并要求您承担相应法律和经济责任。</p>
				<p>本协议受中华人民共和国法律管辖。如您与“无限AI”就本协议的相关问题发生争议，双方均有权向所在地人民法院提起诉讼。</p>
				<br/>
				<br/>
				<p style="text-align: right;">无限AI</p>
				<p style="text-align: right;">2023年2月10日</p>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import { register } from '@/api/api'

export default {
	name: 'Home',
	data() {
		return {
			form: {
				phone: '',
				userPwd: '',
				validate: ''
			},
			pattern: /\d{6}/,
			message: '',
			checked: false,
			agreementDialog: false
		}
	},
	filters: {

	},
	mounted() {
		
	},
	methods: {
		backApp() {
			if(window.history.length === 1){
				window.PosObj.back();
			}else{
				this.$router.go(-1);
			}
		},
		validPhone(val) {
			return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(val);
		},
		validPwd(val) {
			return /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Za-z!@#$%^&*? ])\S*$/.test(val);
		},
		async register() {
			if(!this.checked) {
				return this.$toast('请阅读并同意《无限AI用户协议》后继续');
			}

			this.$toast.loading({
				message: '加载中...',
				forbidClick: true,
				loadingType: 'spinner',
				duration: 0
			});
			const { code, result, message } = await register(this.form)

			this.$toast.clear()
			if(code === 200) {
				this.$dialog.alert({
					title: '提示',
					message: '注册成功',
					confirmButtonText: '立即登录'
				}).then(() => {
					this.backApp()
				});
			} else {
				this.$toast(message)
			}
		}
	},
}
</script>

<style lang="less" scoped>
.page {
	background-color: #f7f8fa;
}
::v-deep {
	.van-nav-bar {
		padding-top: 20px;
		background-color: #4979df;
	}
	.van-nav-bar__title {
		color: #ffffff;
	}
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: .8rem;

	.txt-logo {
		margin-top: 15px;
	}
}

.form-box {
	padding: .3rem;
	max-width: 500px;
	margin: 0 auto;

	.form-title {
		font-size: 14px;
		color: #333;
		padding: 30px 16px 5px;
	}

	.van-cell {
		background-color: transparent;
		padding: 5px 16px;

		&::after {
			border-bottom: 1px solid #9b9b9b;
		}
	}

	.form-checked {
		padding: 30px 16px 5px;
		font-size: 12px;
		display: flex;
		align-items: center;
		color: #666;

		span {
			line-height: 18px;
			color: #1989fa;
		}
		::v-deep {
			.van-checkbox__label {
				color: #666;
				line-height: 18px;
			}
		}
	}
}

.btn-box {
	display: flex;
	justify-content: center;
	margin: 0 auto;
	margin-top: 40px;
	max-width: 500px;

	.van-button {
		width: 2.5rem;
		margin: 0 .2rem;
	}
}

.agreement-content {
	padding: .3rem;
	max-height: 50vh;
	overflow-y: auto;
	text-align: justify;

	p {
		margin: 0;
		margin-bottom: .2rem;
	}
}
</style>
  