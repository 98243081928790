<template>
	<div class="page">
		<van-nav-bar title="指令集流程" @click-left="back">
			<template #left>
				<van-icon name="arrow-left" size="24" color="#fff" />
			</template>
		</van-nav-bar>

		<div class="form-box">
			<van-empty description="暂无"  v-if="!desc"/>
			<div v-html="desc"></div>
		</div>
	</div>
</template>

<script>
import { template } from '@/api/api'

export default {
	name: 'Home',
	data() {
		return {
			desc: ''
		}
	},
	filters: {

	},
	mounted() {
		this.getTemplate(this.$route.query)
	},
	methods: {
		back() {
			this.$router.go(-1);
		},
		async getTemplate(scriptcode) {
			const { code, result, message } = await template(scriptcode)

			if(code == 200) {
				this.desc = result
			} else {

			}
		}
	},
}
</script>

<style lang="less" scoped>
.page {
	background-color: #f7f8fa;
}
::v-deep {
	.van-nav-bar {
		padding-top: 20px;
		background-color: #4979df;
	}
	.van-nav-bar__title {
		color: #ffffff;
	}
}


.form-box {
	padding-top: .3rem;

	.form-title {
		font-size: .28rem;
		color: #333;
		padding: .2rem .3rem;
	}

	.text-center {
		text-align: center;
	}

	.activity-desc {
		font-size: .24rem;
		padding: 0 .3rem;
		margin: 0;
		margin-bottom: .1rem;
	}
}

.btn-box {
	display: flex;
	justify-content: center;
	margin-top: .8rem;

	.van-button {
		width: 2.5rem;
		margin: 0 .2rem;
	}
}
</style>
  