/*
 * @Description:
 * @Version: 1.0
 * @Autor: 杨凯
 * @Date: 2021-05-06 15:21:56
 * @LastEditors: 杨凯
 * @LastEditTime: 2021-12-29 17:30:33
 */
import axios from 'axios'
import { Toast } from 'vant'	//引入Toast
import router from '../router';

// create an axios instance
const service = axios.create({
  baseURL: '/api', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

let token = window.PosObj ? window.PosObj.getStorageItem('token') : '';

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Token'] = token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 5000 || res.code === 401 || res.code === 666) {
        // Toast.fail('微信登录中')
        localStorage.clear()
        sessionStorage.clear()
        // location.reload()
        if(window.PosObj) {
          window.PosObj && window.PosObj.removeStorageItem("token")
          window.PosObj && window.PosObj.goLogin();
        } else {
          // location.reload()
          router.push('/login')
        }
      }
      return res
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Toast.fail('系统异常')
    return Promise.reject(error)
  }
)

export default service
