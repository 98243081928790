<template>
	<div class="page">
		<van-nav-bar title="登录" @click-left="backIndex">
			<template #left>
				<van-icon name="wap-home" size="24" color="#fff" />
			</template>
		</van-nav-bar>

		<div class="logo">
			<van-image
				round
				width="90px"
				height="90px"
				fit="cover"
				:src="require('@/assets/logo.png')"
			/>
			<van-image
				class="txt-logo"
				width="100px"
				:src="require('@/assets/logo2.png')"
			/>
		</div>
		
		<van-form validate-first @submit="login">
			<div class="form-box">
				<div class="form-title">
					手机号
				</div>
				<van-field
					v-model="form.username"
					placeholder="请输入手机号"
					:rules="[{ require: true, message: '手机号输入有误' }]"
				/>
				<div class="form-title">
					密码
				</div>
				<van-field
					v-model="form.password"
					type="password"
					placeholder="请输入密码"
					:rules="[{ require: true, message: '密码由数字与字母组成，且至少6位' }]"
				/>
				
				<van-checkbox v-model="checked" icon-size="18px" style="display: none;">同意</van-checkbox>
			</div>
	
			<div class="btn-box">
				<div class="register" @click="goRegister">
					注册新账号
				</div>
				<van-button type="info" native-type="submit">登录</van-button>
			</div>
		</van-form>

		<van-dialog
			v-model="agreementDialog"
			title="《无限AI用户协议》"
			show-cancel-button
			confirmButtonText="同意"
			@confirm="checked = true">
			<div class="agreement-content">
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
				我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议我是协议
			</div>
		</van-dialog>
	</div>
</template>

<script>
import { login } from '@/api/api'

export default {
	name: 'Home',
	data() {
		return {
			form: {
				username: '',
				password: ''
			},
			pattern: /\d{6}/,
			message: '',
			checked: false,
			agreementDialog: false
		}
	},
	filters: {

	},
	mounted() {
		
	},
	methods: {
		backIndex() {
			location.href = 'https://rpahome.insfair.cn/'
		},
		async login() {
			this.$toast.loading({
				message: '加载中...',
				forbidClick: true,
				loadingType: 'spinner',
				duration: 0
			});
			const { code, result, message } = await login(this.form)

			this.$toast.clear()
			if(code === 200) {
				localStorage.setItem('userinfo', JSON.stringify(result))
				this.$toast('登录成功')
				this.$router.push('/')
			} else {
				this.$toast(message)
			}
		},
		goRegister() {
			this.$router.push('/register')
		}
	},
}
</script>

<style lang="less" scoped>
.page {
	background-color: #f7f8fa;
}
::v-deep {
	.van-nav-bar {
		background-color: #4979df;
	}
	.van-nav-bar__title {
		color: #ffffff;
	}
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: .8rem;

	.txt-logo {
		margin-top: 15px;
	}
}

.form-box {
	padding: .3rem;
	max-width: 500px;
	margin: 0 auto;

	.form-title {
		font-size: 14px;
		color: #333;
		padding: 30px 16px 5px;
	}

	.van-cell {
		background-color: transparent;
		padding: 5px 16px;

		&::after {
			border-bottom: 1px solid #9b9b9b;
		}
	}

	.form-checked {
		padding: .3rem;
		font-size: 12px;
		display: flex;
		align-items: center;
		color: #666;

		span {
			line-height: 18px;
			color: #1989fa;
		}
		::v-deep {
			.van-checkbox__label {
				color: #666;
				line-height: 18px;
			}
		}
	}
}

.btn-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 40px;

	.register {
		font-size: 14px;
		margin-bottom: 30px;
	}

	.van-button {
		width: 250px;
		margin: 0 15px;
	}
}

.agreement-content {
	padding: .3rem;
	max-height: 50vh;
	overflow-y: auto;
	text-align: justify;
}
</style>
  