/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 杨凯
 * @Date: 2021-12-23 16:45:55
 * @LastEditors: 杨凯
 * @LastEditTime: 2022-01-19 17:55:58
 */
import request from '@/utils/request'


// 藏品详情
export function register(data) {
  return request({
    url: '/customer/registerandlogin',
    method: 'post',
    data
  })
}

export function login(params) {
  return request({
    url: '/user/login',
    method: 'post',
    params
  })
}

export function getmytasks(params) {
  return request({
    url: '/customer/getmytasks',
    method: 'get',
    params
  })
}

export function savetask(data) {
  return request({
    url: '/customer/savetask',
    method: 'post',
    data
  })
}

export function tousu(content) {
  return request({
    url: '/user/tousu',
    method: 'put',
    data: {
      content
    }
  })
}

export function template(scriptcode) {
  return request({
    url: '/users/template',
    method: 'get',
    params: {
      scriptcode
    }
  })
}

export function getCommentLog(params) {
  return request({
    url: '/ai/getCommentLog',
    method: 'get',
    params
  })
}

export function getTask(params) {
  return request({
    url: '/ai/getTask',
    method: 'post',
    params
  })
}

export function saveTask(data) {
  return request({
    url: '/ai/saveTask',
    method: 'post',
    data
  })
}