<template>
	<div class="page">
		<van-nav-bar title="投诉与建议" @click-left="backApp">
			<template #left>
				<van-icon name="arrow-left" size="24" color="#fff" />
			</template>
		</van-nav-bar>

		<div class="form-box">
			<van-field
				v-model="content"
				:autosize="{ minHeight: 160 }"
				type="textarea"
				placeholder="请输入投诉与建议"
			/>
		</div>

		<div class="btn-box">
			<van-button type="info" @click="tousu">提交</van-button>
		</div>
	</div>
</template>

<script>
import { tousu } from '@/api/api'

export default {
	name: 'Home',
	data() {
		return {
			content: ''
		}
	},
	filters: {

	},
	mounted() {
		
	},
	methods: {
		backApp() {
			window.PosObj.back();
		},
		async tousu() {
			if(!this.content.trim()) {
				this.$toast('请输入投诉与建议')
			} else {
				const { code, result, message } = await tousu(this.content)

				if(code === 200) {
					this.$toast('提交成功，感谢您提出的宝贵建议')
				
					setTimeout(() => {
						this.backApp()
					}, 2000);
				} else {
					this.$toast(message)
				}
			}
		}
	},
}
</script>

<style lang="less" scoped>
.page {
	background-color: #f7f8fa;
}
::v-deep {
	.van-nav-bar {
		padding-top: 20px;
		background-color: #4979df;
	}
	.van-nav-bar__title {
		color: #ffffff;
	}
}


.form-box {
	padding-top: .3rem;

	.form-title {
		font-size: .28rem;
		color: #333;
		padding: .2rem .3rem;
	}
}

.btn-box {
	display: flex;
	justify-content: center;
	margin-top: .8rem;

	.van-button {
		width: 2.5rem;
		margin: 0 .2rem;
	}
}
</style>
  