<template>
  <div class="page">
    <van-nav-bar title="生成爆款评论" @click-left="backApp">
      <template #left>
        <van-icon name="arrow-left" size="24" color="#fff" />
      </template>
    </van-nav-bar>

    <div class="form-box">
      <van-field class="border" v-model="content" :autosize="{ minHeight: 80 }" type="textarea" placeholder="请把需要生成评论的视频链接复制到这里：" />
      <div class="form-tips">
        <p>示例：https://v.douyin.com/D5xkW51/</p>
        <p>注意：其他格式无法识别</p>
      </div>
    </div>

    <div class="btn-box" v-if="status !== 200">
      <van-button type="info" @click="generate" :loading="isloading" loading-text="生成中，请勿操作手机 ...">{{ btnText }}</van-button>
      <div class="error-text">本次生成失败，请重试或更换视频链接</div>
    </div>
    <div class="btn-box" v-else>
      <van-button type="info" @click="generate" block :loading="isloading" loading-text="生成中，请勿操作手机 ...">{{ btnText }}</van-button>
    </div>

    <van-divider />

    <div class="comment">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="comment-item border" v-for="item in commentList" :key="item.timestamp">
          <div class="comment-header">
            <div class="comment-title van-ellipsis">
              视频：{{ item.desc }}
            </div>
            <div class="comment-time">
              {{ item.dt | formtime }}
            </div>
          </div>
          <van-divider :style="{ margin: '0', borderColor: 'rgba(0, 0, 0, 0.24)', width: '100%' }" />
          <div class="comment-body">
            <div class="comment-box" v-for="(comment, i) in item.robotComment" :key="'comment' + i">
              {{ comment.comment }}
              <div class="comment-btn">
                <span class="copy-btn" @click="copyComment(comment.comment)">
                  <van-icon :name="require('@/assets/static/img/copy.png')" />
                  复制
                </span>
              </div>
            </div>
            <div class="comment-tips">
              请选择一条评论，点击右下角复制按钮，并手动发布到对应视频下
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard';
import { getCommentLog } from '@/api/api'

export default {
  name: "Home",
  data() {
    return {
      content: "",
      result: "",
      btnText: "开始生成",
      isloading: false,
      commentList: [],
      status: 200,
      loading: false,
      finished: false,
      params: {
        page: 1,
        size: 10
      }
    };
  },
  filters: {},
  mounted() {
    window.rpa = this;
    this.getCommentLog()
  },
  methods: {
    backApp() {
      // window.PosObj.back();
      this.$router.go(-1);
    },
    async getCommentLog() {
      const { code, result, message } = await getCommentLog(this.params)

      if(code == 200) {
        this.commentList.push(...result.list)
      }
    },
    async onLoad() {
      this.params.page += 1
      const { code, result, message } = await getCommentLog(this.params)

      if(code == 200) {
        this.loading = false;
        this.commentList.push(...result.list)

        if(this.params.page >= result.pages) {
          this.finished = true;
        }
      }
    },
    generate() {
      if (!this.content.trim()) {
        this.$toast("请填入视频链接");
      } else {
        this.isloading = true;
        this.status = 200;
        window.PosObj.setClipboardData(this.content);
        this.startTool("com.openai.dycommenthelper.dex", "生成爆款评论");
      }
    },
    startTool(dexName, title) {
      window.PosObj.startTool(dexName, title.substring(0, 10));
    },
    setRunnerStop(scriptcode) {
      console.log(scriptcode + " stop");
    },
    onDexResult(res) {
      this.isloading = false;
      const { code, message, result, attach, timestamp } = JSON.parse(res);

      this.status = code;
      if (code == 200) {
        this.btnText = "开始生成";
        this.commentList.unshift({
          dt: timestamp,
          ...attach,
          robotComment: result,
        });
      } else {
        this.btnText = "重新生成";
      }
    },
    copyComment(text) {
      copy(text)
      this.$toast('复制成功')
    }
  },
};
</script>

<style lang="less" scoped>
.page {
  background-color: #fff;
  padding-bottom: 1rem;
}
::v-deep {
  .van-nav-bar {
    padding-top: 20px;
    background-color: #4979df;
  }
  .van-nav-bar__title {
    color: #ffffff;
  }
}

.form-box {
  padding: 0.3rem;

  .form-title {
    font-size: 0.28rem;
    color: #333;
    padding: 0.2rem 0.3rem;
  }

  .form-tips {
    font-size: 12px;
    margin-top: 0.2rem;
    padding: 0 0.2rem;
    color: #666;

    p {
      margin: 0;
    }
  }
}

.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
  padding: 0 0.3rem;

  .error-text {
    font-size: 14px;
    margin-left: 10px;
  }

  .van-button {
    min-width: 100px;
  }
}

.comment {
  font-size: 0.28rem;
  padding: .3rem;
  
  .comment-item {
    overflow: hidden;
    margin-bottom: .3rem;

    .comment-header {
      padding: .3rem;
    }

    .comment-time {
      margin-top: .1rem;
    }

    .comment-body {
      padding: .3rem;
    }

    .comment-box {
      padding: .3rem;
      border-radius: 5px;
      background: #f5f5f5;
      margin-bottom: .3rem;
    }

    .comment-btn {
      margin-top: .1rem;
      display: flex;
      justify-content: flex-end;
    }

    .copy-btn {
      font-size: .24rem;
      display: flex;
      align-items: center;
      color: #707070;
    }

    .comment-tips {
      font-size: 12px;
      margin-top: 0.2rem;
      padding: 0 0.2rem;
      color: #666;
    }
  }
}
</style>

<style lang="less">
.comment {
  
  .comment-item {
    overflow: hidden;
    margin-bottom: .3rem;
  }
}
.border {
  border-radius: 5px;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
  
  &:before {
    border: 1px solid rgba(0, 0, 0, 0.24);
    transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: inherit;
  }
  
  &:after {
    height: inherit;
    border-radius: inherit;
    border: 2px solid transparent;
    transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
</style>
