<template>
  <div class="page" ref="scrollDiv">
    <van-nav-bar title="创建指令集" @click-left="back">
      <template #left>
        <van-icon name="arrow-left" size="24" color="#fff" />
      </template>
    </van-nav-bar>

    <van-form validate-first @submit="showSaveDialog">
      <div class="form-box">
        <div class="form-title">
          描述您的需求：
        </div>
        <van-field v-model="form.content" :autosize="{ minHeight: 200 }" type="textarea" placeholder="请输入..." />
        <div class="form-title">
          指令集名称(选填)
        </div>
        <van-field v-model="form.title" placeholder="给你的指令集起个名字" />
      </div>

      <div class="btn-box">
        <van-button type="default" @click="back" native-type="button">返回</van-button>
        <van-button type="info" native-type="submit">立即创建</van-button>
      </div>
    </van-form>

    <van-divider :style="{ borderColor: '#999', padding: '0 .3rem', marginBottom: 0 }">为你推荐</van-divider>

    <div class="card-list">
      <div class="single-card">
        <div class="single-card-header">
          <div class="icon">
            <van-icon name="friends-o" size="24px" />
            <p>4819在用</p>
          </div>
          <div class="info">
            <h4>GPT模板批量运行</h4>
            <p>当前AI版本 3.1</p>
          </div>
        </div>
        <div class="single-card-body">
          <p>自动按计划运行GPT，获得想要的结果</p>
        </div>
        <div class="single-card-bottom cursor-pointer" @click="insertText('我要GPT模板批量运行', '我的GPT模板批量运行')">
          <div class="single-card-price">
            1999/年
          </div>
          <div class="single-card-handle">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="single-card">
        <div class="single-card-header">
          <div class="icon">
            <van-icon name="friends-o" size="24px" />
            <p>1685在用</p>
          </div>
          <div class="info">
            <h4>自动将需求拆解成指令集</h4>
            <p>当前AI版本 3.1</p>
          </div>
        </div>
        <div class="single-card-body">
          <p>将工作需求分解成可重复规模运行的智能流程!自动生成[无限AI]可用的步骤</p>
        </div>
        <div class="single-card-bottom">
          <div class="single-card-price">
            限时免费（原1500/年）
          </div>
          <div class="single-card-handle cursor-pointer" @click="insertText('我要使用自动将需求拆解成指令集功能', 'AI拆解需求以符合[无限AI]指令集要求')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="single-card">
        <div class="single-card-header">
          <div class="icon">
            <van-icon name="friends-o" size="24px" />
            <p>2912在用</p>
          </div>
          <div class="info">
            <h4>原始ChatGPT</h4>
            <p>当前AI版本 2.1.4.0</p>
          </div>
        </div>
        <div class="single-card-body">
          <p>即开即用的ChatGPT，写文章、回答问题、提供建议、写代码</p>
        </div>
        <div class="single-card-bottom">
          <div class="single-card-price">
            限时免费（原280/年）
          </div>
          <div class="single-card-handle cursor-pointer" @click="insertText('我要使用ChatGPT', '原始ChatGPT')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="single-card">
        <div class="single-card-header">
          <div class="icon">
            <van-icon name="friends-o" size="24px" />
            <p>831在用</p>
          </div>
          <div class="info">
            <h4>自动引流：GPT生产抖音爆款评论</h4>
            <p>当前AI版本 6.101</p>
          </div>
        </div>
        <div class="single-card-body">
          <p>对爆款视频进行深度分析，并自动生成爆款评论进行抖音引流</p>
        </div>
        <div class="single-card-bottom">
          <div class="single-card-price">
            1999/年
          </div>
          <div class="single-card-handle cursor-pointer" @click="insertText('我要使用抖音评论引流', '引流：GPT生产抖音爆款评论')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="single-card">
        <div class="single-card-header">
          <div class="icon">
            <van-icon name="friends-o" size="24px" />
            <p>1209在用</p>
          </div>
          <div class="info">
            <h4>文案AI补充改写</h4>
            <p>当前AI版本 3.09</p>
          </div>
        </div>
        <div class="single-card-body">
          <p>将爆款文案或主题改写成具有你的特色的高流量文案</p>
        </div>
        <div class="single-card-bottom">
          <div class="single-card-price">
            限时免费（原499/年）
          </div>
          <div class="single-card-handle cursor-pointer" @click="insertText('我要自动生成爆款文案', '自动生成爆款文案')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="single-card">
        <div class="single-card-header">
          <div class="icon">
            <van-icon name="friends-o" size="24px" />
            <p>640在用</p>
          </div>
          <div class="info">
            <h4>自动抖音互粉涨粉强力起号</h4>
            <p>当前AI版本 4.8</p>
          </div>
        </div>
        <div class="single-card-body">
          <p>为抖音号快速积累基础粉丝量，并自动化维护</p>
        </div>
        <div class="single-card-bottom">
          <div class="single-card-price">
            820/年
          </div>
          <div class="single-card-handle cursor-pointer" @click="insertText('我要使用抖音互粉涨粉起号', '抖音互粉涨粉起号')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <van-dialog
      v-model="saveDialog"
      title="创建本指令集前，您需了解并同意以下使用声明："
      show-cancel-button
      confirm-button-text="同意并创建"
      confirm-button-color="#4979df"
      cancel-button-color="#999"
      :overlayStyle="{
        backgroundColor: 'rgba(0,0,0,.3)',
      }"
      @confirm="savetask"
    >
      <div class="dialog-content">
        您确认您提供的指令集中不包含任何计算机暴力或引起第三方软件封禁在内的违规行为。
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { savetask } from "@/api/api";

export default {
  name: "Home",
  data() {
    return {
      form: {
        content: "",
        title: "",
      },
      saveDialog: false,
    };
  },
  filters: {},
  mounted() {},
  methods: {
    back() {
      if (window.history.length === 1) {
        window.PosObj.back();
      } else {
        this.$router.go(-1);
      }
    },
    async savetask() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      const { code, result, message } = await savetask(this.form);

      this.$toast.clear();
      if (code === 200) {
        this.$toast("创建成功");

        setTimeout(() => {
          this.back();
        }, 2000);
      } else {
        this.$toast(message);
      }
    },
    showSaveDialog() {
      if (!this.form.content) {
        return this.$toast("请输入您的需求");
      }

      this.saveDialog = true;
    },
    insertText(content, title) {
      this.form.content = content;
      this.form.title = title;
      let customAnimScroll = window.setInterval(function() {
        let topOffset = window.pageYOffset;
        if (topOffset > 0) {
          window.scrollTo({ top: topOffset - 20 });
        } else {
          clearInterval(customAnimScroll);
        }
      }, 20);
			this.$dialog.alert({
				title: '提示',
				message: '指令已自动填充， 请点击[立即创建]按钮开始使用。',
				confirmButtonText: '好的'
			}).then(() => {
				// on close
			});
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background-color: #f7f8fa;
}
::v-deep {
  .van-nav-bar {
    padding-top: 20px;
    background-color: #4979df;
  }
  .van-nav-bar__title {
    color: #ffffff;
  }
}

.form-box {
  padding-top: 0.3rem;

  .form-title {
    font-size: 0.28rem;
    color: #333;
    padding: 0.2rem 0.3rem;
  }
}

.btn-box {
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;

  .van-button {
    width: 2.5rem;
    margin: 0 0.2rem;
  }
}

::v-deep {
  .van-dialog .van-dialog__header {
    padding: 26px 30px 10px;
    text-align: left;
  }
}

.dialog-content {
  padding: 0 30px 30px;
  font-size: 14px;
  color: #333;
}

.single-card {
  max-width: 460px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #ffffff;
  background-image: linear-gradient(313deg, #ffffff 0%, #ffffff 34%, #fff6e9 43%, #f8faff 53%, #ffffff 66%, #ffffff 100%);

  .single-card-header {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .single-card-header .icon {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3194fd;
    background-image: linear-gradient(135deg, #3194fd 0%, #509ef7 40%, #83bdff 75%, #d7ebff 100%);
    flex-shrink: 0;
    color: #ffffff;
    font-size: 12px;
  }

  .single-card-header .icon p {
    line-height: 1;
    margin: 0;
    margin-top: 10px;
  }

  .single-card-header .info h4 {
    font-size: 0.32rem;
    margin: 0;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .single-card-header .info p {
    font-size: 0.24rem;
    color: #999;
  }

  .single-card-body {
    padding: 0 15px 15px;
    color: #666;
  }

  .single-card-body p {
    font-size: 0.28rem;
    margin: 0;
    line-height: 1.5;
    text-align: justify;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .single-card-bottom {
    font-size: 0.28rem;
    display: flex;
    padding: 15px;
    border-top: 1px solid #efefef;
    justify-content: space-between;
    line-height: 1;
    color: #3194fd;
  }
}

.card-list {
  padding: 0.3rem;

  .more {
    padding-top: 20px;
    font-size: 12px;
    text-align: center;
    color: #333;

    p {
      margin: 0;
      margin-bottom: 5px;
    }
  }
}
</style>
