<template>
  <div class="page">
    <van-nav-bar title="指令集" @click-left="backApp">
      <template #left>
        <van-icon name="wap-home" size="24" color="#fff" />
      </template>
      <template #right>
        <van-icon name="plus" size="24" color="#fff" @click="goCreate" v-if="isInApp" />
        <span v-else style="color: #fff">{{ username | formatUsername }}</span>
      </template>
    </van-nav-bar>

    <div class="filter-box" v-if="isInApp">
      <van-button size="small" :plain="tag !== undefined" type="info" @click="getmytasks()">全部指令集 {{ s0 }}</van-button>
      <van-button size="small" :plain="tag !== 0" type="primary" @click="getmytasks(0)">可用 {{ s1 }}</van-button>
      <van-button size="small" :plain="tag !== 1" type="danger" @click="getmytasks(1)">不可用 {{ s2 }}</van-button>
      <van-button size="small" :plain="tag !== 3" type="warning" @click="getmytasks(3)">创建中 {{ s3 }}</van-button>
    </div>

    <div class="directive-list" v-if="isInApp">
      <van-empty description="暂无" v-if="!taskList.length" />
      <van-cell-group>
        <van-cell v-for="(task, index) in taskList" :key="task.id">
          <template #title>
            <div class="direct-content">
              <div class="direct-img" @click="showTemplate(task.scriptcode)">
                <vue-letter-avatar :name="formatAvatar(task.title || task.content)" class="direct-avatar" :rounded="true" />
              </div>
              <div class="direct-info">
                <p class="direct-time">创建时间：{{ task.dt | formtime }}</p>
                <p class="direct-title van-ellipsis">{{ task.title || task.content }}</p>
              </div>
            </div>
            <div class="status">
              <span class="primary" v-if="task.status === 9">运行中</span>
              <span class="info" v-if="task.status === 0" @click="showTemplate(task.scriptcode)"> 可用<van-icon name="notes-o" /> </span>
              <span class="danger" v-if="task.status === 1">不可用</span>
              <span class="warning" v-if="task.status === 2">解析完成</span>
              <span class="warning" v-if="task.status === 3">创建中</span>
              <div class="feedback">最新反馈：{{ task.feekbackServer }}</div>
            </div>
          </template>
          <template #extra>
            <div class="direct-handle">
              <div class="handle-box">
                <van-button size="small" type="danger" v-if="task.status === 9" @click="stopTool(task.scriptcode, index)">停止</van-button>
                <van-button size="small" type="primary" v-if="task.status === 0 && task.type === 1" @click="startTool(task.scriptcode, task.title || task.content, index)">开启</van-button>
                <van-button size="small" type="primary" v-if="task.status === 0 && task.type === 2" @click="goLink(task.url)">使用</van-button>
                <van-button size="small" v-if="task.status === 1" @click="showReason(task.feekbackServer)">查看原因</van-button>
                <van-button size="small" v-if="task.status === 2" @click="showQrcodeShow(task.feekbackServer)">待支付</van-button>
                <van-button size="small" v-if="task.status === 3" @click="showProgress(task.feekbackServer)">查看进度</van-button>
              </div>
              <div class="status-text van-ellipsis">
                {{ task.reason }}
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="card-list" v-else-if="this.username && !isInApp">
      <div class="card-content">
        <div class="single-card">
          <div class="single-card-header">
            <div class="icon">
              <van-icon name="friends-o" size="24px" />
              <p>4819在用</p>
            </div>
            <div class="info">
              <h4>GPT模板批量运行</h4>
              <p>当前AI版本 3.1</p>
            </div>
          </div>
          <div class="single-card-body">
            <p>自动按计划运行GPT，获得想要的结果</p>
          </div>
          <div class="single-card-bottom cursor-pointer" @click="goGptModel">
            <div class="single-card-price">
              1999/年
            </div>
            <div class="single-card-handle">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="single-card">
          <div class="single-card-header">
            <div class="icon">
              <van-icon name="friends-o" size="24px" />
              <p>2912在用</p>
            </div>
            <div class="info">
              <h4>原始ChatGPT</h4>
              <p>当前AI版本 2.1.4.0</p>
            </div>
          </div>
          <div class="single-card-body">
            <p>即开即用的ChatGPT，写文章、回答问题、提供建议、写代码</p>
          </div>
          <div class="single-card-bottom cursor-pointer" @click="goGpt">
            <div class="single-card-price">
              限时免费（原280/年）
            </div>
            <div class="single-card-handle">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="single-card">
          <div class="single-card-header">
            <div class="icon">
              <van-icon name="friends-o" size="24px" />
              <p>1685在用</p>
            </div>
            <div class="info">
              <h4>自动将需求拆解成指令集</h4>
              <p>当前AI版本 3.1</p>
            </div>
          </div>
          <div class="single-card-body">
            <p>将工作需求分解成可重复规模运行的智能流程!自动生成[无限AI]可用的步骤</p>
          </div>
          <div class="single-card-bottom cursor-pointer" @click="notOpen">
            <div class="single-card-price">
              限时免费（原1500/年）
            </div>
            <div class="single-card-handle">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="single-card">
          <div class="single-card-header">
            <div class="icon">
              <van-icon name="friends-o" size="24px" />
              <p>1209在用</p>
            </div>
            <div class="info">
              <h4>文案AI补充改写</h4>
              <p>当前AI版本 3.09</p>
            </div>
          </div>
          <div class="single-card-body">
            <p>将爆款文案或主题改写成具有你的特色的高流量文案</p>
          </div>
          <div class="single-card-bottom cursor-pointer" @click="notOpen">
            <div class="single-card-price">
              限时免费（原499/年）
            </div>
            <div class="single-card-handle">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="more">
        <p>更多AI功能请<b @click="toIndex">下载安卓版APP</b></p>
        <p>苹果用户建议准备一台备用安卓机</p>
      </div>
    </div>
    <div class="directive-list" v-else>
      <van-empty description="暂无" />
    </div>

    <van-dialog v-model="qrcodeShow" title="解析完成" confirm-button-text="关闭">
      <div class="qrcode-box">
        <p>{{ feekbackServer }}</p>
        <van-image width="100%" :src="require('@/assets/static/img/qrcode2.jpg')" />
        <p>微信扫码加客服并支付，即可自动激活</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { getmytasks } from "@/api/api";
import deepClone from "@/utils/deepClone";
import { pinyin } from "pinyin-pro";

export default {
  name: "Home",
  data() {
    return {
      taskList: [],
      tag: undefined,
      s0: 0,
      s1: 0,
      s2: 0,
      s3: 0,
      qrcodeShow: false,
      feekbackServer: "",
      username: "",
    };
  },
  computed: {
    isInApp() {
      return !!window.PosObj;
    },
  },
  filters: {
    formatUsername(val) {
      if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(val)) {
        return val.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2");
      } else {
        return val;
      }
    },
  },
  mounted() {
    window.rpa = this;
    this.getmytasks(undefined, true);
    let userinfo = localStorage.getItem("userinfo");
    if(userinfo && !this.isInApp) {
      this.username = JSON.parse(userinfo).username;
    }
  },
  methods: {
    backApp() {
      if (this.isInApp) {
        PosObj.back();
      } else {
        location.href = "https://rpahome.insfair.cn/";
      }
    },
    goCreate() {
      this.$router.push("/create");
    },
    async getmytasks(status, isFirst) {
      this.tag = status;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      const { code, result, message } = await getmytasks({ page: 1, size: 50, status });

      if (code == 200) {
        let list = deepClone(result);
        let runnerCode = window.PosObj ? window.PosObj.getCurrentRunner() : "";

        list.forEach((item) => {
          if (!!runnerCode && runnerCode == item.scriptcode) {
            item.status = 9;
          }

          if (status == undefined && isFirst) {
            this.s0 = list.length;
            if (item.status == 0) {
              this.s1 += 1;
            } else if (item.status == 1) {
              this.s2 += 1;
            } else if (item.status == 3) {
              this.s3 += 1;
            }
          }
        });

        this.taskList = list;
      } else {
        this.$toast(message);
      }
      this.$toast.clear();
    },
    showTemplate(scriptcode) {
      if (!scriptcode) return;
      this.$router.push("/template?scriptcode=" + scriptcode);
    },
    findRunner() {
      return this.taskList.findIndex((item) => item.status === 9);
    },
    setRunner(scriptcode) {
      this.taskList.forEach((item) => {
        if (!!scriptcode && scriptcode == item.scriptcode) {
          item.status = 9;
        }
      });
    },
    setRunnerStop(scriptcode) {
      this.taskList.forEach((item) => {
        if (!!scriptcode && scriptcode == item.scriptcode) {
          item.status = 0;
        }
      });
    },
    startTool(dexName, title, index) {
      let runnigTask = this.findRunner();
      if (runnigTask === -1) {
        if (dexName === "com.openai.dycommenthelper.dex") {
          this.$router.push("/gptgenerate");
        } else if (dexName === "com.openai.gptbatch") {
          this.$router.push("/gptmodel");
        } else {
          window.PosObj.startTool(dexName, title.substring(0, 10));
        }
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: "当前有正在运行的指令集，是否运行新的指令集？",
          })
          .then(() => {
            this.stopTool(this.taskList[runnigTask].scriptcode, runnigTask);
            if (dexName === "com.openai.dycommenthelper.dex") {
              this.$router.push("/gptgenerate");
            } else if (dexName === "com.openai.gptbatch") {
              this.$router.push("/gptmodel");
            } else {
              window.PosObj.startTool(dexName, title.substring(0, 10));
            }
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    goLink(url) {
      let token = ''

      if(this.isInApp) {
        token = window.PosObj.getStorageItem('token')
      } else {
        const userinfo = JSON.parse(localStorage.getItem('userinfo'))
        token = userinfo.description
      }

      let runnigTask = this.findRunner();
      if (runnigTask === -1) {
        this.$toast.loading({
          message: "正在跳转...",
          forbidClick: true,
          duration: 0,
        });
        location.href = url + '?token=' + token;
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: "当前有正在运行的指令集，是否运行新的指令集？",
          })
          .then(() => {
            this.stopTool(this.taskList[runnigTask].scriptcode, runnigTask);
            this.$toast.loading({
              message: "正在跳转...",
              forbidClick: true,
              duration: 0,
            });
            location.href = url + '?token=' + token;
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    stopTool(dexName, index) {
      this.taskList[index].status = 0;
      window.PosObj.stopTool(dexName);
    },
    formatAvatar(word) {
      // const { pinyin } = pinyinPro;
      let firstLetter = [...word][0];
      return pinyin(firstLetter, { pattern: "first" });
    },
    showReason(reason) {
      this.$dialog
        .alert({
          title: "不可用原因",
          message: reason,
        })
        .then(() => {
          // on close
        });
    },
    showProgress(progress) {
      this.$dialog
        .alert({
          title: "当前进度",
          message: progress,
        })
        .then(() => {
          // on close
        });
    },
    showQrcodeShow(reason) {
      this.feekbackServer = reason;
      this.qrcodeShow = true;
    },
    notOpen() {
      this.$toast('未获权限')
    },
    goGpt() {
      let token = ''

      if(this.isInApp) {
        token = window.PosObj.getStorageItem('token')
      } else {
        const userinfo = JSON.parse(localStorage.getItem('userinfo'))
        token = userinfo.description
      }

      location.href = 'http://chatgpt.insfair.cn/?token=' + token
    },
    goGptModel() {
      this.$router.push("/gptmodel");
    },
    toIndex() {
			location.href = 'https://rpahome.insfair.cn/'
		},
  },
};
</script>

<style lang="less" scoped>
.page {
  background-color: #f7f8fa;
}
::v-deep {
  .van-nav-bar {
    padding-top: 20px;
    background-color: #4979df;
  }
  .van-nav-bar__title {
    color: #ffffff;
  }
}

.filter-box {
  display: flex;
  justify-content: center;
  padding: 0.3rem 0;

  .van-button {
    margin: 0 0.1rem;
  }
}

.directive-list {
  .direct-content {
    display: flex;

    p {
      margin: 0;
      line-height: 1;
    }

    .direct-img {
      display: flex;
      margin-right: 0.3rem;

      .direct-avatar {
        width: 1.2rem !important;
        height: 1.2rem !important;
      }
    }

    .direct-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .direct-time {
      font-size: 0.2rem;
    }

    .direct-title {
      max-width: 14em;
      font-size: 0.32rem;
      margin-bottom: 0.15rem;
    }
  }

  .status {
    display: flex;
    margin-top: 0.1rem;

    span {
      min-width: 1.2rem;
      text-align: center;
      font-size: 0.28rem;
      font-weight: 600;

      .van-icon {
        margin-left: 0.08rem;
      }

      &.primary {
        color: #07c160;
      }

      &.info {
        color: #4979df;
      }

      &.danger {
        color: #ee0a24;
      }

      &.warning {
        color: #ff976a;
      }
    }

    .feedback {
      margin-left: 0.3rem;
      font-size: 0.24rem;
    }
  }

  .direct-handle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .handle-box {
    height: 1rem;
    display: flex;
    align-items: center;
  }

  .status-text {
    max-width: 5em;
    margin-top: 0.1rem;
    font-size: 0.24rem;
  }
}

.qrcode-box {
  padding: 0.3rem;

  p {
    margin-top: 0;
  }
  img {
    width: 100%;
  }
}

.single-card {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #ffffff;
  background-image: linear-gradient(313deg, #ffffff 0%, #ffffff 34%, #fff6e9 43%, #f8faff 53%, #ffffff 66%, #ffffff 100%);

  .single-card-header {
    display: flex;
    align-items: center;
    padding: 15px;
  }
  
  .single-card-header .icon {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3194fd;
    background-image: linear-gradient(135deg, #3194fd 0%, #509ef7 40%, #83bdff 75%, #d7ebff 100%);
    flex-shrink: 0;
    color: #ffffff;
    font-size: 12px;
  }
  
  .single-card-header .icon p {
    line-height: 1;
    margin: 0;
    margin-top: 10px;
  }
  
  .single-card-header .info h4 {
    font-size: 16px;
    margin: 0;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .single-card-header .info p {
    font-size: 12px;
    color: #999;
  }
  
  .single-card-body {
    padding: 0 15px 15px;
    color: #666;
  }
  
  .single-card-body p {
    font-size: 14px;
    margin: 0;
    line-height: 1.5;
    text-align: justify;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .single-card-bottom {
    font-size: 14px;
    display: flex;
    padding: 15px;
    border-top: 1px solid #efefef;
    justify-content: space-between;
    line-height: 1;
    color: #3194fd;
  }
}

.card-list {
  padding: 30px 15px;
  max-width: 1020px;
  margin: 0 auto;
  
  .card-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .more {
    padding-top: 20px;
    font-size: 12px;
    text-align: center;
    color: #333;

    p {
      margin: 0;
      margin-bottom: 5px;
    }
  }
}

@media (max-width: 1040px) { 
  .card-list .card-content {
    justify-content: center;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
