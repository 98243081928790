<template>
	<div class="page">
		<van-nav-bar title="联系客服" @click-left="backApp">
			<template #left>
				<van-icon name="arrow-left" size="24" color="#fff" />
			</template>
		</van-nav-bar>

		<div class="form-box">
			<div class="form-title">
				请添加下方客服群咨询
			</div>
			<van-image
				width="100%"
				:src="require('@/assets/static/img/qrcode2.jpg')"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Home',
	data() {
		return {
			message: ''
		}
	},
	filters: {

	},
	mounted() {
		
	},
	methods: {
		backApp() {
			window.PosObj.back();
		}
	},
}
</script>

<style lang="less" scoped>
.page {
	background-color: #f7f8fa;
}
::v-deep {
	.van-nav-bar {
		padding-top: 20px;
		background-color: #4979df;
	}
	.van-nav-bar__title {
		color: #ffffff;
	}
}


.form-box {
	padding-top: .3rem;

	.form-title {
		font-size: .28rem;
		color: #333;
		padding: .2rem .3rem;
		text-align: center;
	}
}

.btn-box {
	display: flex;
	justify-content: center;
	margin-top: .8rem;

	.van-button {
		width: 2.5rem;
		margin: 0 .2rem;
	}
}
</style>
  